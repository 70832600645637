<template>
    <div class="contain">
        <div class="login-area">
            <div class="top-area">
                <div class="title">CityBanana - 經紀人後台</div>
                <div class="title2">在城市中追求美好時刻</div>
                <div class="circle"></div>
            </div>
            <div class="bottom-area">
                <div class="inputs">
                    帳號
                    <el-input
                        v-model="inputs.login"
                        class="mt-2 mb-2"
                        clearable
                        placeholder="輸入帳號"
                    >
                    </el-input>
                    密碼
                    <el-input
                        v-model="inputs.password"
                        type="password"
                        class="mt-2"
                        clearable
                        placeholder="輸入密碼"
                    >
                    </el-input>
                </div>
                <button class="btn-login" @click.prevent="onSubmit()">
                    登入
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { phoneOptions } from "@/config/optionsConfig.js";

// 使用 swwetalert2 套件
import Swal from "sweetalert2";
// 導入 vuex
import { mapActions, mapMutations } from "vuex";
// 導入 localStorage 設定值方法
import { setStorage } from "@/service/localStorage";
export default {
    name: "LoginBroker",
    data() {
        return {
            phoneOptions,
            // 測試資料
            // inputs: {
            //     phone_country: "886",
            //     phone: "912345678",
            //     password: "123456",
            // },
            inputs: {
                login: "",
                password: "",
            },
        };
    },
    methods: {
        ...mapActions("userStore", ["getLoginBrokerInfo"]),
        ...mapActions("firestoreChatStore", ["getFirebaseTokenByBroker"]),
        ...mapMutations("userStore", ["setIsAuth", "setUser"]),
        ...mapActions("i18nStore", ["getLang", "getErrorMessagesList"]),
        ...mapActions("chatStore", [
            "getFirebaseToken",
            "getFirebaseBrokerToken",
        ]),
        async onSubmit() {
            try {
                const { data } = await this.$api.LoginBrokerApi(this.inputs);
                if (data.user.status === -1) {
                    Swal.fire({
                        position: "top-end",
                        toast: true,
                        icon: "error",
                        title: "此帳號已被停權",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    return;
                }
                await Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "登入成功",
                    showConfirmButton: false,
                    timer: 1500,
                });
                // 設定 token 到 localStorage
                setStorage("token", "Bearer " + data.access_token);
                // 設定使用者資料
                await this.setUser(data.user);
                await this.getLoginBrokerInfo();
                await this.getFirebaseTokenByBroker();
                // 設定重取 broker api token 時間
                setStorage(
                    "brokerTokenRefreshTime",
                    this.$moment().add(7, "days").valueOf()
                );
                // 設定重取 firebase token 時間
                setStorage(
                    "firebaseTokenRefreshTime",
                    this.$moment().add(30, "minutes").valueOf()
                );
                // 設定使用者為登入狀態
                this.setIsAuth(true);
                await this.getErrorMessagesList();
                this.$router.push({ name: "broker_home" }).catch((e) => {
                    console.log(e);
                });
            } catch (err) {
                Swal.fire({
                    position: "top-end",
                    toast: true,
                    icon: "error",
                    title: "登入失敗",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.contain {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-area {
        width: 80%;
        min-width: 375px;
        max-width: 800px;
        height: auto;
        border-radius: 15px;
        background: #fff;
        padding-bottom: 30px;
        @media only screen and (max-width: 600px) {
            min-height: 100vh;
            min-width: 100%;
        }
        .top-area {
            width: 100%;
            height: 300px;
            position: relative;
            overflow: hidden;
            font-weight: 700;
            color: #fff;
            font-size: 30px;
            .title {
                position: absolute;
                z-index: 2;
                top: 60px;
                left: 10%;
            }
            .title2 {
                position: absolute;
                top: 120px;
                left: 10%;
                z-index: 2;
            }
            .circle {
                width: 700px;
                height: 700px;
                background: linear-gradient(45deg, #f74a23, #f15f6d, #f12d8f);
                border-radius: 50%;
                position: absolute;
                top: -400px;
                left: -150px;
                z-index: 1;
            }
            @media only screen and (max-width: 600px) {
                font-size: 24px;
            }
        }
        .bottom-area {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 50px;
            .inputs {
                width: 80%;
                max-width: 500px;
                font-size: 18px;
                color: rgb(88, 86, 86);
                font-weight: 500;
            }
        }
        .title3 {
            font-weight: 700;
            font-size: 24px;
        }
        .btn-login {
            width: 200px;
            height: 40px;
            background: rgb(255, 87, 51);
            border-radius: 20px;
            font-weight: 700;
            color: #fff;
            font-size: 16px;
            margin: 40px;
        }
    }
}
</style>
